import baseAxios from '@/api/base';

// baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;
/**
 * 測驗去-科目列表
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetGotoPractice = (data) => baseAxios.post('/FrontEnd/BuyPractice/GotoPracticeByMid', data);

export const GotoPracticeCategoryByMid = (data) => baseAxios.post('/FrontEnd/BuyPractice/GotoPracticeCategoryByMid', data);

export const GetPracticeCategoryByMid = (data) => baseAxios.post('/FrontEnd/BuyPractice/GetPracticeCategoryByMid', data);

/**
 * 測驗去-作答
*  @param {object} data {
  "questionId": 1, // 題目
  "responderAnswers": [
        2
      ] //答題選項
}
 */
export const ResponderPracticeByMid = (data) => baseAxios.post('/FrontEnd/BuyPractice/ResponderPracticeByMid', data);

/**
 * 測驗去-取得知識點列表
*  @param {object} data {
  "pageIndex": 1,
  "pageSize": 10,
  "data": [
    "subjectId": 1
  ]
}
 */
export const GetPracticeKnowledgeByMid = (data) => baseAxios.post('/FrontEnd/BuyPractice/GotoPracticeKnowledgeByMid', data);

/**
 * 題目難易度下拉
 * @param {object} data { type }
 */
export const GetPracticeQuestionDifficulty = (type = 99) => baseAxios.post('/FrontEnd/BuyPractice/GetPracticeQuestionDifficulty', { type });

/**
 * 題目類型下拉選單
 * @param {object} data { type }
 */
export const GetPracticeQuestionType = (type = 99) => baseAxios.post('/FrontEnd/BuyPractice/GetPracticeQuestionType', { type });

/**
 * 考卷塞選條件下拉
 * @param {object} data { type }
 */
export const GetPracticeFilter = (type = 99) => baseAxios.post('/FrontEnd/BuyPractice/GetPracticeFilter', { type });

/**
 * 麵包屑
 * @param {object} data { type }
 */

export const GetPracticeCategoryBreadcrumb = (data) => baseAxios.post('/FrontEnd/BuyPractice/GetPracticeCategoryBreadcrumb', data);

export const GetPracticeSbujectBreadcrumb = (data) => baseAxios.post('/FrontEnd/BuyPractice/GetPracticeSbujectBreadcrumb', data);
