<template>
  <section class="container pb-8 ">
    <div class="error-bg mb-3 mb-md-8" v-if="PageInfo.data.length === 0">
      <div>查無題庫資料</div>
    </div>

    <ul  class="mb-3" v-else-if="PageInfo.data.length !== 0">
      <router-link
        class="btn btn-info mr-3"
        :to="`/practices/exam/${this.subjectId}`"
      >
        考試去
      </router-link>

      <router-link
        class="btn btn-info"
        :to="`/practices/knowledge/${this.subjectId}`"
      >
        知識點分析
      </router-link>

      <li
        v-for="(item, index) in PageInfo.data"
        :key="index"
        class="exam-category"
      >
        <h5>{{ item.categoryName }}</h5>

        <template v-if="item.questionTotal !== 0">
          <p class="exam-category__progress">
            完成度：{{ item.questionCorrect }}/{{ item.questionTotal }}
          </p>

          <router-link
            v-if="item.questionTotal !== 0"
            class="btn btn-primary exam-category__btn"
            :to="`/practices/exercise/${item.categoryId}`"
          >
            所有題目
          </router-link>
        </template>

        <p
          v-else
          class="exam-category__btn exam-category__btn--disabled"
        >
          暫無題目
        </p>
      </li>
    </ul>

    <!-- 分頁 -->
    <Pagination
      @GetList="GetList()"
      :page.sync="Pagination.pageIndex"
      :page-row="Pagination.pageSize"
      :total-count="PageInfo.count"
    />
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { GotoPracticeCategoryByMid } from '@/api/BuyPracticeApi';

export default {
  components: {
    Pagination,
  },
  data() {
    // 建立你的初始化 model data
    return {
      subjectId: 0,
      Args: { subjectId: '' },
      Pagination: {
        // 有分頁要有此model data
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          subjectId: '',
        },
      },
      PageInfo: {
        count: 0, // api給的總筆數
        data: [],
      },
    };
  },
  // 當有設定keep的時候，返回到此頁面會執行這個，不會執行created()，所以這邊就不用放created()
  async activated() {
    const { id } = this.$route.params;
    this.subjectId = id;
    if (!this.$route.meta.isBack) {
      // 不是從指定頁面返回的話，進行初始化設定
      this.Args = { subjectId: id };
      this.Pagination = {
        pageIndex: 1, // 預設從第幾頁開始
        pageSize: 10, // 預設每頁幾筆
        data: {
          subjectId: id,
        },
      };
      this.PageInfo = {
        count: 0, // api給的總筆數
        data: [],
      };
    }
    // 然後再針對頁面進行初始化資料存取
    this.GetList();
  },
  methods: {
    GetList() {
      const { id } = this.$route.params;
      this.subjectId = id;
      this.Args = { subjectId: id };
      this.Pagination.data = { ...this.Args };
      GotoPracticeCategoryByMid(this.Pagination).then((response) => {
        if (response.data.statusCode === 0) {
          this.PageInfo.count = response.data.count;
          this.PageInfo.data = response.data.data;

          if (this.PageInfo.data.length > 0) {
            this.$emit('update:currentPage', [
              {
                name: '',
                title: `${this.PageInfo.data[0].subjectName}`,
                path: '',
              },
            ]);
          }

          if (this.Pagination.pageIndex !== 1 && this.PageInfo.data.length === 0) {
            this.Pagination.pageIndex = 1;
            this.GetList();
          }
        }
      });
    },
  },
};
</script>

<style></style>
