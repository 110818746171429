<template>
  <nav v-if="this.totalPage !== 0" aria-label="navigation">
    <ul class="pagination justify-content-end" id="pagination">
      <li class="page-item" :class="{ disabled: !prevPage }">
        <a class="page-link" href="#" @click.prevent="changePage((currentPage -= 1))">前一頁</a>
      </li>
      <li
        class="page-item"
        :class="{ active: item == currentPage }"
        v-for="item in pageNumbers"
        :key="item"
      >
        <a class="page-link current" href="#" @click.prevent="changePage(item)">{{ item }}</a>
      </li>
      <li class="page-item" :class="{ disabled: !nextPage }">
        <a class="page-link" href="#" @click.prevent="changePage((currentPage += 1))">下一頁</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['page', 'pageRow', 'pageCount', 'totalCount'],
  mounted() {
    this.setPages();
  },
  data() {
    return {
      currentPage: 0, // 目前頁數
      pageShow: 5, // 顯示多少頁碼
      totalPage: 0, // 總頁數
      pageNumbers: [],
      prevPage: false,
      nextPage: false,
    };
  },
  methods: {
    setPages() {
      // 取得當前頁
      const currentPage = this.page ? parseInt(this.page, 10) : 1;
      this.currentPage = currentPage;

      // 取得總頁數
      this.totalPage = Math.ceil(this.totalCount / this.pageRow);

      // 設定顯示基數
      let pageNumbersCount = 0;

      // 因為是單頁使用如果有東西要先刪掉
      if (this.pageNumbers.length !== 0) {
        this.pageNumbers = [];
      }

      for (
        let page = currentPage - 2 <= 0 ? 1 : currentPage - 2;
        pageNumbersCount < this.pageShow && page <= this.totalPage;
        page += 1
      ) {
        pageNumbersCount += 1;
        this.pageNumbers.push(page);
      }

      // 確定啟用上一頁下一頁
      this.prevPage = currentPage !== 1;
      this.nextPage = currentPage !== this.totalPage;
    },
    changePage(pages) {
      this.$emit('update:page', pages);
      this.$emit('GetList');
    },
  },
  watch: {
    page() {
      this.setPages();
    },
    totalCount() {
      this.setPages();
    },
  },
};
</script>

<style></style>
